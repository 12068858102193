import React from 'react';
import { When } from 'react-if';
import { useDispatch } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { Box, LoaderDots, Spacing, Text } from '@reservamos/elements';
import LoyaltyOption from '../atoms/LoyaltyOption';
import useLoyaltyPrograms from '../../loyalty/context/useLoyaltyPrograms';
import { handleLoyaltySelection } from '../../actions/loyalty';
import usePurchase from '../../hooks/store/usePurchase';

/** Component used to select a loyalty program where there are two sessions at the same time */
const LoyaltySelection = () => {
  const {
    doters: { profile: dotersProfile },
    costaPass: { profile: costaPassProfile, config: costaPassConfig },
  } = useLoyaltyPrograms();
  const { isUpdatingWalletType } = usePurchase();
  const { t } = useTranslation('loyalty');
  const dispatch = useDispatch();

  const { balance } = costaPassProfile;
  const { availablePoints } = dotersProfile;

  const profilesTypes = {
    doters: dotersProfile,
    costapass: costaPassProfile,
  };

  /**
   * Handles the selection of a loyalty program.
   * @param {string} loyaltyProgram - The name of the loyalty program to select.
   * @returns {Function} A function that dispatches the handleLoyaltySelection action.
   */
  const handleSelection = (loyaltyProgram) => () => {
    dispatch(
      handleLoyaltySelection({
        loyaltyProgram,
        user: profilesTypes[loyaltyProgram],
        updatePurchase: true,
        needsUnlockSeats: true,
      }),
    );
  };

  const { prefix } = costaPassConfig;

  return (
    <Box
      border="all"
      borderColor="info"
      borderRadius="M"
      paddingHorizontal="M"
      paddingVertical="S"
      paddingHorizontalMobile="S"
      paddingVerticalMobile="S"
    >
      <Spacing vertical>
        <Text textAlign="center" mobileSize="S" color="grayMedium">
          {t('choose_the_account_to_use')}
        </Text>
        <Spacing
          alignItems="center"
          justifyContent="space-between"
          responsiveSize="S"
          fullWidth
          flexGrow
        >
          <When condition={isUpdatingWalletType}>
            <LoaderDots isAccent />
          </When>
          <When condition={!isUpdatingWalletType}>
            <LoyaltyOption
              onSelect={handleSelection('doters')}
              balance={availablePoints}
              loyaltyProgram="doters"
            />
            <LoyaltyOption
              onSelect={handleSelection(prefix)}
              balance={balance}
              loyaltyProgram={prefix}
            />
          </When>
        </Spacing>
      </Spacing>
    </Box>
  );
};

export default LoyaltySelection;
