/* eslint-disable react/jsx-props-no-spreading */
import React from 'react';
import { Spacing, Text, BoxCollapse, Icon, Box } from '@reservamos/elements';
import { useTranslation } from 'react-i18next';
import PropTypes from 'prop-types';
import './PointsSlider.scss';
import PointsSliderContent from './PointsSliderContent';
import NotPointsContent from './NotPointsContent';

/** PointsSlider component is the main component used to show a wallet points salider */
const PointsSlider = ({
  sliderPoints,
  balance,
  handleOnSubmitPoints,
  maxPoints,
  minPoints = 0,
  handleOnChange,
  handleOnEdit,
  handleOnDelete,
  disabled,
  pointsApplied,
  isLoading,
  headerTitle,
  sliderHeader,
  minPointsText,
  maxPointsText,
  pointsAppliedText,
  errorMessage,
  editButtonText,
  deleteButtonText,
  hideModifiersButtons,
  rechargeURL,
  steps = '1',
  notPoints,
  notPointsMessage,
  showAddPointsButton,
  loyalty,
  canApplyPoints,
  showInput,
  inputValue,
  handleUpdateWallet,
  defaultExpanded = false,
  availablePoints,
  hasHeaderColor,
  showIconCheck = true,
}) => {
  const showCheck = !!(pointsApplied && disabled);

  const sliderContentProps = {
    sliderHeader,
    sliderPoints,
    handleOnChange,
    steps,
    maxPoints,
    minPoints,
    disabled,
    isLoading,
    minPointsText,
    maxPointsText,
    handleOnSubmitPoints,
    pointsApplied,
    errorMessage,
    balance,
    editButtonText,
    deleteButtonText,
    handleOnEdit,
    handleOnDelete,
    hideModifiersButtons,
    loyalty,
    canApplyPoints,
    showAddPointsButton,
    rechargeURL,
    showInput,
    inputValue,
    handleUpdateWallet,
    availablePoints,
    showIconCheck,
  };

  const { t } = useTranslation(['payment', 'loyalty']);

  const textColor = loyalty === 'doters' ? 'loyalty' : 'primary';

  return (
    <div className={`${loyalty}-slider`}>
      <BoxCollapse
        defaultExpanded={defaultExpanded}
        titleContent={
          <Spacing size="XS" fullWidth justifyContent="space-between">
            <Spacing size="S" alignItems="center">
              {showIconCheck && showCheck && <Icon type="checkGreen" />}

              <div>
                {showCheck ? (
                  <Spacing alignItems="center" size="XS">
                    <Text
                      color={loyalty === 'doters' ? 'white' : 'primary'}
                      weight="bold"
                      size="S"
                      xsMobileSize="XS"
                    >
                      {t('payment:label.pay_with')}
                    </Text>

                    <Box
                      alphaBg="M"
                      paddingHorizontal={loyalty === 'doters' ? 'S' : null}
                      paddingVertical="XXS"
                      borderRadius="L"
                    >
                      <Text
                        color={loyalty === 'doters' ? 'white' : 'primary'}
                        weight="bold"
                        size="S"
                        xsMobileSize="XS"
                      >
                        {pointsAppliedText}
                      </Text>
                    </Box>

                    {loyalty === 'doters' && (
                      <Text
                        color={loyalty === 'doters' ? 'white' : 'primary'}
                        weight="bold"
                        size="S"
                        xsMobileSize="XS"
                      >
                        {t('payment:label.doter_points')}
                      </Text>
                    )}
                  </Spacing>
                ) : (
                  <Text weight="bold" mobileSize="S" color={hasHeaderColor ? 'white' : textColor}>
                    {headerTitle}
                  </Text>
                )}
              </div>
            </Spacing>
            <i
              className={`slider-logo  ${
                hasHeaderColor && loyalty === 'doters' ? `${loyalty}-white` : `${loyalty}-logo`
              }`}
            />
          </Spacing>
        }
        paddingHorizontal="S"
        paddingVertical="S"
        paddingHorizontalMobile="S"
        paddingVerticalMobile="S"
        borderColor={loyalty === 'doters' ? 'grayBorder' : 'primary'}
        dividerColor="grayBorder"
        borderRadius="M"
        bgHeader={loyalty === 'doters' ? 'loyalty' : 'white'}
        headerArrowColor={hasHeaderColor ? 'white' : 'primary'}
      >
        {notPoints ? (
          <NotPointsContent
            showAddPointsButton={showAddPointsButton}
            notPointsMessage={notPointsMessage}
            rechargeURL={rechargeURL}
            handleUpdateWallet={handleUpdateWallet}
          />
        ) : (
          <PointsSliderContent {...sliderContentProps} />
        )}
      </BoxCollapse>
    </div>
  );
};

PointsSlider.propTypes = {
  sliderPoints: PropTypes.string,
  balance: PropTypes.number,
  maxPoints: PropTypes.number,
  minPoints: PropTypes.number,
  handleOnSubmitPoints: PropTypes.func,
  handleOnChange: PropTypes.func,
  handleOnEdit: PropTypes.func,
  handleOnDelete: PropTypes.func,
  pointsApplied: PropTypes.number,
  isLoading: PropTypes.bool,
  disabled: PropTypes.bool,
  headerTitle: PropTypes.node,
  sliderHeader: PropTypes.string,
  minPointsText: PropTypes.string,
  maxPointsText: PropTypes.string,
  pointsAppliedText: PropTypes.string,
  errorMessage: PropTypes.string,
  editButtonText: PropTypes.string,
  deleteButtonText: PropTypes.string,
  hideModifiersButtons: PropTypes.bool,
  rechargeURL: PropTypes.string,
  steps: PropTypes.string,
  showAddPointsButton: PropTypes.bool,
  notPoints: PropTypes.bool,
  notPointsMessage: PropTypes.string,
  loyalty: PropTypes.string,
  canApplyPoints: PropTypes.bool,
  showInput: PropTypes.bool,
  inputValue: PropTypes.number,
  handleUpdateWallet: PropTypes.func,
  defaultExpanded: PropTypes.bool,
  availablePoints: PropTypes.number,
  hasHeaderColor: PropTypes.bool,
  showIconCheck: PropTypes.bool,
};
export default PointsSlider;
