/**
 * Parse user agent string to get platform-specific properties
 *
 * @param {string} userAgent - The user agent string
 * @returns {Object | null} Extended properties with platform-specific data or null if the user agent is not supported
 */
const parseUserAgent = (userAgent) => {
  // Check for native app identifier with optional API version for Android
  const nativeAppMatch = userAgent.match(/native_app_(ios|android(?:_(\d+))?)/i);
  if (!nativeAppMatch) {
    return null;
  }

  const platform = nativeAppMatch[1].toLowerCase().split('_')[0]; // Extract 'ios' or 'android'
  const androidApiVersion = nativeAppMatch[2]; // Will be undefined for iOS

  const baseProps = {
    product: platform,
    platform: platform === 'android' ? 'android' : undefined,
    $browser: userAgent,
  };

  // Add Android API version if available from our custom identifier
  if (platform === 'android' && androidApiVersion) {
    return {
      ...baseProps,
      'Android API Version': parseInt(androidApiVersion, 10),
    };
  }

  return baseProps;
};

/**
 * Return user agent properties for mixpanel
 * If user agent is not from native app, return super properties
 *
 * @param {Object} superProps - The super properties
 * @returns {Object} The user agent properties
 */
export default function getMixpanelUserAgentProps(superProps) {
  const userAgentProps = parseUserAgent(window.navigator.userAgent);

  return userAgentProps || superProps;
}
