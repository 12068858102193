import React from 'react';
import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';
import { Text, ButtonCard, Spacing, MessageBox } from '@reservamos/elements';
import { Trans, useTranslation } from 'react-i18next';
import paypal from 'images/payment/paypal.svg';
import coppelPay from 'images/payment/coppelpay.png';
import oxxo from 'images/payment/oxxo.svg';
import tarjetaNoAmex from 'images/payment/tarjeta-2.svg';
import tarjeta from 'images/payment/tarjeta.svg';
import tarjetaNoMC from 'images/payment/tarjeta-visa-amex.svg';
import tarjetaPt from 'images/payment/tarjeta-pt.svg';
import pse from 'images/payment/pse.png';
import kueskipay from 'images/payment/kueskipay.png';
import isotypeSeven from 'images/payment/seven.png';
import isotypeAurrera from 'images/payment/isotype-aurrera.png';
import isotypeCirclek from 'images/payment/isotype-circlek.png';
import isotypeSoriana from 'images/payment/isotype-soriana.png';
import isotypeWallmart from 'images/payment/isotype-wallmart.png';
import pixLogo from 'images/payment/pix.png';
import efectyLogo from 'images/payment/efecty.png';
import nequiLogo from 'images/payment/nequi.svg';
import viajamas from 'images/brands-unique/etn/viajamas.png';
import moveObjToFirst from 'utils/sortObjectArray';
import useWhitelabelEnvs from 'hooks/whitelabel/useWhitelabelEnvs';
import useWhitelabelFeatures from 'hooks/whitelabel/useWhitelabelFeatures';
import BadgeNew from './BadgeNew';
import './PaymentSelector.scss';

/**
 * PaymentSelector component allows users to select a payment method.
 *
 * @param {Object} props - Component props.
 * @param {string[]} props.availablePayments - List of available payment methods.
 * @param {Object[]} props.paymentMethods - List of payment method objects.
 * @param {string} props.selectedOption - Currently selected payment method.
 * @param {Function} props.onPaymentTypeChange - Callback function when payment type changes.
 * @returns {React.Component} A component that renders the payment options.
 */
const PaymentSelector = ({
  availablePayments,
  paymentMethods = [],
  selectedOption,
  onPaymentTypeChange,
}) => {
  const { supportAmexCard, defaultPaymentOption, brand } = useWhitelabelEnvs();
  const features = useWhitelabelFeatures();
  const selectedPaymentMethod = useSelector((state) => state.purchase.get('selectedPaymentMethod'));
  const yuno = useSelector((state) => state.purchase.get('yuno'));
  const { t } = useTranslation('payment');

  const payCashImages = [
    isotypeAurrera,
    isotypeSeven,
    isotypeCirclek,
    isotypeSoriana,
    isotypeWallmart,
  ];

  /**
   * Determines the appropriate card logo based on available card brands and Amex support.
   *
   * @returns {string} The path to the card logo image.
   */
  const getCardLogo = () => {
    const hasCybersourceBrands = features.CYBERSOURCE_AVAILABLE_CARD_BRANDS?.length > 0;
    const supportsMastercard = features.CYBERSOURCE_AVAILABLE_CARD_BRANDS?.includes('mastercard');

    if (hasCybersourceBrands && !supportsMastercard) {
      return tarjetaNoMC;
    }

    return supportAmexCard ? tarjeta : tarjetaNoAmex;
  };

  /**
   * Each method object can have up to 2 levels
   * type -> provider
   * where provider is the deepest level
   *
   * paymentMethods also have an engine property, but it's not relevant
   * to display the selector for each method
   */
  const methods = {
    transfer: {
      pse: {
        img: pse,
        label: t('button.payment_method', { context: 'transfer' }),
        isNew: false,
      },
      pix: {
        img: pixLogo,
        label: t('button.payment_method', { context: 'pix' }),
        isNew: false,
      },
      nequi: {
        img: nequiLogo,
        label: t('button.payment_method', { context: 'nequi' }),
        isNew: true,
      },
      evertec: {
        img: pse,
        label: t('button.payment_method', { context: 'transfer' }),
        isNew: false,
      },
    },
    store: {
      isStoreIcon: true,
      label: t('button.payment_method', { context: 'store' }),
      isNew: false,
      oxxo: {
        img: oxxo,
        label: t('button.payment_method', { context: 'oxxo' }),
        isNew: false,
      },
      paycash: {
        label: t('button.payment_method', { context: 'store' }),
        isNew: true,
        images: payCashImages,
        inlineImages: true,
      },
    },
    credit_card: {
      img: getCardLogo(),
      label: t('button.payment_method', { context: 'credit' }),
      isNew: false,
      evertec: {
        img: supportAmexCard ? tarjeta : tarjetaNoAmex,
        label: t('button.payment_method', { context: 'credit' }),
        isNew: false,
      },
      cielo: {
        img: tarjetaPt,
        label: t('button.payment_method', { context: 'credit' }),
        isNew: false,
      },
    },
    third_party: {
      coppel_pay: {
        img: coppelPay,
        label: t('button.payment_method', { context: 'coppelpay' }),
        isNew: true,
      },
    },
    // Notice that some of this first levels are not payment types indeed
    // This is because in order to have a progressive migration to the new paymentMethods structure
    // we need to keep the support for the old one (availablePayments)
    paypal: {
      img: paypal,
      label: t('button.payment_method', { context: 'paypal' }),
      isNew: false,
    },
    paycash: {
      label: t('button.payment_method', { context: 'store' }),
      isNew: true,
      images: payCashImages,
      inlineImages: true,
    },
    kueskipay: {
      img: kueskipay,
      label: t('button.payment_method', { context: 'loan' }),
      isNew: true,
    },
    reservamos_pay: {
      img: viajamas,
      label: t('button.payment_method', { context: 'reservamos_pay' }),
      isNew: false,
    },
    efecty: {
      img: efectyLogo,
      label: t('button.payment_method', { context: 'efecty' }),
      isNew: false,
    },
  };

  // There are 2 structures for payment methods:
  // 1. paymentMethods: the new structure, which is an array of objects
  // 2. availablePayments: the old structure, which is an array of strings
  // We need to parse the old structure to match the new one
  // The API keeps both structures for backwards compatibility
  const parsedAvailablePayments = availablePayments
    // Filter availablePayments to exclude those payment methods
    // that are already defined in paymentMethods
    .filter(
      (payment) =>
        !paymentMethods.some((method) => method.type === payment || method.provider === payment),
    )
    // Parse availablePayments to match the paymentMethods structure
    // to get an unified list in the next step
    .map((payment) => {
      return {
        type: payment,
      };
    });

  const parsedYunoPayments =
    yuno?.availableMethods?.map((method) => {
      const category = method?.category?.toLowerCase() || ''; // Handles undefined `method` or `category`
      const categoryTypeMap = {
        ticket: 'store',
        payment_link: 'transfer',
        buy_now_pay_later: 'bnpl',
      };

      const type = categoryTypeMap[category] || category;

      return {
        label:
          category === 'buy_now_pay_later'
            ? t('button.payment_bnpl')
            : t('button.payment_method', { context: 'yuno_default' }),
        provider: 'yuno',
        engine: method.type.toLowerCase(),
        type,
        img: method.icon,
        enabled: method.checkout.conditions.enabled,
      };
    }) || [];

  let payments = [...parsedAvailablePayments, ...paymentMethods, ...parsedYunoPayments];

  /**
   * Returns a function that handles the selection of a payment method.
   *
   * @param {Object} param - The parameter object.
   * @param {Object} param.payment - The payment method object.
   * @param {Function} [param.closeModal] - Optional function to close the modal.
   * @returns {Function} A function to be called when a payment method is selected.
   */
  const getHandleSelectPayment =
    ({ payment, closeModal }) =>
    () => {
      const isNewStructure = Boolean(payment.type && payment.provider);

      onPaymentTypeChange(payment.type, isNewStructure ? payment : undefined);
      if (closeModal) closeModal();
    };

  /**
   * Renders payment method cards for selection.
   *
   * @param {Function} [closeModal] - Optional function to close the modal after selection.
   * @returns {React.Component} A component that renders payment method cards.
   */
  const renderPaymentCards = (closeModal = undefined) => {
    return (
      <div className="payment-selector">
        {payments.map((payment) => {
          // Change the type of the payment method from app_notify to transfer
          // This is needed because the API returns app_notify as the type of the payment method for nequi
          // but we need to use transfer as the type to match the paymentMethods structure
          if (payment.type === 'app_notify') {
            payment.type = 'transfer';
          }

          const { type, provider, engine } = payment;
          const isYuno = Boolean(payment.provider === 'yuno');
          const methodType = isYuno
            ? {
                img: payment.img,
                label: payment.label,
              }
            : methods[type];
          const usingNewConfig = Boolean(selectedPaymentMethod.provider || provider);

          if (!methodType && type !== 'pix') return null;

          if ((payment.engine === 'yuno' || !payment.enabled) && isYuno) return null;

          let method = {};
          if (!methodType && type === 'pix') {
            method = methods.transfer.pix;
          } else {
            method = methodType[provider] || methodType;
            const isOldStructure = !provider;
            if (isOldStructure && type === 'transfer') {
              method = methodType.pse;
            }
          }

          const isActive =
            (selectedPaymentMethod?.provider === provider &&
              selectedPaymentMethod?.provider !== 'yuno') ||
            selectedPaymentMethod?.engine === engine;

          return (
            <div className="payment-selector-badge">
              {method.isNew && (
                <div className="payment-selector-badge-wrapper">
                  <BadgeNew />
                </div>
              )}
              <ButtonCard
                imgSrc={method.img}
                images={method.images}
                isStoreIcon={method.isStoreIcon}
                inlineImages={method.inlineImages}
                onClick={getHandleSelectPayment({ payment, closeModal })}
                // Soon we will only use the selectedPaymentOption, which has te new object structure.
                // When this happens this conditions needs to change to something like: type === selectedPaymentOption.type
                isActive={usingNewConfig ? isActive : type === selectedOption}
              >
                <Text color="grayMedium" size="XS" whiteSpace="nowrap">
                  {method.label}
                </Text>
              </ButtonCard>
            </div>
          );
        })}
      </div>
    );
  };

  if (payments.length === 1) return null;

  if (features.DEFAULT_PAYMENT_OPTION_FIRST) {
    payments = moveObjToFirst(payments, 'type', defaultPaymentOption);
  }

  return (
    <>
      <Spacing vertical size="S" responsiveSize="XS">
        <Text weight="bold" size="XL">
          {t('select_payment_method')}
        </Text>
        {features.SHOW_PAYMENT_DISCLAIMER && (
          <MessageBox borderColor="primary">
            <Text>
              <Trans t={t} i18nKey="text.payment_disclaimer" tOptions={{ context: brand }}>
                text<b>text</b>text
              </Trans>
            </Text>
          </MessageBox>
        )}
        {renderPaymentCards()}
      </Spacing>
    </>
  );
};

PaymentSelector.propTypes = {
  selectedOption: PropTypes.string,
  availablePayments: PropTypes.array,
  onPaymentTypeChange: PropTypes.func,
  paymentMethods: PropTypes.array,
};

PaymentSelector.defaultProps = {
  selectedOption: '',
  availablePayments: [],
  onPaymentTypeChange: () => {},
};

export default PaymentSelector;
