import React, { useRef } from 'react';
import { useSelector } from 'react-redux';
import { Spacing, Text, FlatButton, Button, Input, Box } from '@reservamos/elements';
import { useTranslation } from 'react-i18next';
import PropTypes from 'prop-types';
import './PointsSlider.scss';
import DotersNextLevel from 'components/Doters/DotersNextLevel/DotersNextLevel';
import { formatDotersPoints } from '../../../utils/doters';
import LoyaltyPricingRow from './LoyaltyPricingRow';

const PointsSlider = ({
  sliderPoints,
  inputValue,
  balance,
  handleOnSubmitPoints,
  minPoints,
  maxPoints,
  handleOnChange,
  handleOnEdit,
  handleOnDelete,
  disabled,
  canApplyPoints,
  pointsApplied,
  isLoading,
  steps,
  sliderHeader,
  minPointsText,
  maxPointsText,
  editButtonText,
  deleteButtonText,
  hideModifiersButtons,
  loyalty,
  showInput,
  availablePoints,
}) => {
  const rangeInput = useRef(null);
  const { t } = useTranslation(['payment', 'loyalty']);

  const rangePercentage =
    sliderPoints > 0 ? ((sliderPoints - minPoints) * 100) / (maxPoints - minPoints) : 0;

  const minPointsApply = sliderPoints > minPoints;
  const showSlider = !pointsApplied || !disabled;
  const disabledClass = (showSlider && disabled) || isLoading ? 'slider-disabled' : '';
  const showCheck = !!(pointsApplied && disabled);
  const textColor = loyalty === 'doters' ? 'loyalty' : 'primary';
  const sliderPointsValue = !sliderPoints ? minPoints : sliderPoints;
  const remainingPoints = availablePoints - sliderPoints;

  const applyButton = (
    <Button
      onClick={() => handleOnSubmitPoints(sliderPoints)}
      isLoading={isLoading}
      variant={textColor}
      className="button-secondary"
      text={t('loyalty:apply')}
      isDisabled={!canApplyPoints}
      mobileSize="S"
      fullWidthOnSmall
    />
  );

  const pointButtonHandler = (
    <>
      {pointsApplied && sliderPoints === 0 ? (
        <FlatButton size="S" href="#" type="error" onClick={handleOnDelete}>
          {deleteButtonText || t('payment:button.doter_cancel')}
        </FlatButton>
      ) : (
        <>{applyButton}</>
      )}
    </>
  );

  const purchase = useSelector((state) => state.purchase);
  const payment = useSelector((state) => state.payment);

  const { status: purchaseStatus, walletAccrualPoints } = purchase.toJS();

  const { status: paymentStatus } = payment.toJS();
  const haveEarned = paymentStatus === 'charged' || purchaseStatus === 'completed';
  const showApplyButton = !showCheck && !showInput;
  const showDeleteButton =
    !isLoading && pointsApplied && disabled && !hideModifiersButtons && minPointsApply;
  const showEditButton = !isLoading && pointsApplied && disabled && !hideModifiersButtons;

  return (
    <div className={`points-slider ${disabledClass}`}>
      <Spacing vertical size={pointsApplied ? 'S' : 'M'}>
        {showSlider && (
          <Spacing size="S" vertical>
            {sliderHeader}
            <input
              className="rangeslider"
              ref={rangeInput}
              type="range"
              value={sliderPointsValue}
              onChange={handleOnChange}
              step={steps}
              min={minPoints}
              max={maxPoints}
              disabled={disabled || isLoading}
              style={{
                backgroundSize: `${rangePercentage}% 100%`,
              }}
              name="sliderRange"
            />

            <Spacing justifyContent="space-between" alignItems="center">
              <Text size="S" weight="semibold">
                {minPointsText}
              </Text>

              <Text size="S" weight="semibold">
                {maxPointsText}
              </Text>
            </Spacing>
            {showInput && (
              <div className="points-slider-input">
                <Input
                  onChange={handleOnChange}
                  label={t('payment:label.quantity_to_use')}
                  type="number"
                  value={inputValue}
                  name="inputPoints"
                  id="inputPoints"
                />
                {applyButton}
              </div>
            )}
          </Spacing>
        )}

        <Box
          paddingHorizontal="S"
          paddingVertical="S"
          paddingHorizontalMobile="XS"
          paddingVerticalMobile="XS"
          bgColor="grayBorder"
          alphaBg="L"
          borderRadius="M"
        >
          <Box paddingHorizontal="S" paddingVertical="S" borderRadius="M">
            <Spacing size="XS" vertical>
              {loyalty === 'doters' && (
                <>
                  <LoyaltyPricingRow
                    description={`${t('payment:label.to_use_doters')}`}
                    currency={`- ${sliderPoints}`}
                    doterPointsLabel={t('payment:label.doter_points')}
                  />
                  <LoyaltyPricingRow
                    description={`${t('payment:label.remaining_doters_points')}`}
                    currency={`${formatDotersPoints(remainingPoints)}`}
                    doterPointsLabel={t('payment:label.doter_points')}
                  />
                  <LoyaltyPricingRow
                    description={`${t(haveEarned ? 'loyalty:generated' : 'loyalty:will_generate')}`}
                    currency={
                      walletAccrualPoints ? `+ ${walletAccrualPoints}` : walletAccrualPoints
                    }
                    doterPointsLabel={t('payment:label.doter_points')}
                    highlightColor={!!walletAccrualPoints}
                  />
                </>
              )}
              <LoyaltyPricingRow
                description={`${t('payment:label.outstanding_to_pay')}`}
                currency={`${balance}`}
                bold
                canApplyPoints
                doterPointsLabel={t('payment:label.doter_points')}
              />
            </Spacing>
          </Box>
        </Box>

        <Spacing
          alignItems="center"
          justifyContent="space-between"
          fullWidth
          responsiveSize="S"
          isResponsive
          responsiveColumnReverse
        >
          <DotersNextLevel />

          <Spacing alignItems="center" fullWidth justifyContent="flex-end">
            {showDeleteButton ? (
              <Button
                alphaBg="5"
                color="error"
                fontSize="S"
                hideShadow
                iconColor="error"
                isRounded
                minHeight="32px"
                padding="S"
                text={deleteButtonText || t('payment:button.discard')}
                variant="accent"
                fullWidthOnSmall
                onClick={handleOnDelete}
              />
            ) : null}

            {showEditButton ? (
              <Button
                fontSize="S"
                iconColor="grayMedium"
                iconType="EditPencil"
                isRounded
                minHeight="32px"
                padding="S"
                text={editButtonText || t('payment:button.modify_points')}
                whiteSpace="nowrap"
                fullWidthOnSmall
                onClick={handleOnEdit}
              />
            ) : null}

            {showApplyButton && <>{pointButtonHandler}</>}
          </Spacing>
        </Spacing>
      </Spacing>
    </div>
  );
};

PointsSlider.propTypes = {
  sliderPoints: PropTypes.number,
  balance: PropTypes.number,
  minPoints: PropTypes.number,
  maxPoints: PropTypes.number,
  handleOnSubmitPoints: PropTypes.func,
  handleOnChange: PropTypes.func,
  handleOnEdit: PropTypes.func,
  handleOnDelete: PropTypes.func,
  pointsApplied: PropTypes.number,
  isLoading: PropTypes.bool,
  disabled: PropTypes.bool,
  steps: PropTypes.string,
  canApplyPoints: PropTypes.bool,
  sliderHeader: PropTypes.string,
  minPointsText: PropTypes.string,
  maxPointsText: PropTypes.string,
  editButtonText: PropTypes.string,
  deleteButtonText: PropTypes.string,
  loyalty: PropTypes.oneOf(['doters', 'costapass']),
  hideModifiersButtons: PropTypes.bool,
  showInput: PropTypes.bool,
  inputValue: PropTypes.number,
  availablePoints: PropTypes.number,
};

PointsSlider.defaultProps = {
  sliderPoints: 0,
  availablePoints: 0,
  balance: 0,
};

export default PointsSlider;
