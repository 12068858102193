import React from 'react';
import PropTypes from 'prop-types';
import { Text } from '@reservamos/elements';
import { useTranslation } from 'react-i18next';
import useWhitelabelFeatures from 'hooks/whitelabel/useWhitelabelFeatures';
import useWhitelabelEnvs from 'hooks/whitelabel/useWhitelabelEnvs';
import PurchaserFields from './PurchaserFields';
import TaxpayerIdInput from './TaxpayerIdInput';

/**
 * PurchaserForm component.
 * Renders a form section for collecting purchaser information with optional invoice toggle.
 * @param {object} props - showPurchaserForm, initialValues, isExchange, showInvoiceToggle
 * @param {boolean} props.showPurchaserForm - Controls visibility of purchaser form fields
 * @param {object} props.initialValues - Initial values for the purchaser form
 * @param {boolean} props.isExchange - Flag indicating if this is an exchange transaction
 * @param {boolean} props.showInvoiceToggle - Controls visibility of the invoice/taxpayer ID section
 */
const PurchaserForm = ({ showPurchaserForm, initialValues, isExchange, showInvoiceToggle }) => {
  const { t } = useTranslation('payment');
  const { SHOW_PURCHASER_FORM_BILLING_TITLE: showBillingTitle } = useWhitelabelFeatures();
  const { brand } = useWhitelabelEnvs();
  return (
    <>
      {showInvoiceToggle && (
        <div className="checkout-header">
          <Text size="XL" weight="bold" className="checkout-header-title">
            {t('need_invoice')}
          </Text>
          <TaxpayerIdInput />
        </div>
      )}
      <div className="checkout-header">
        <Text size="XL" weight="bold" className="checkout-header-title">
          {showBillingTitle ? t('purchaser_info_billing', { context: brand }) : t('purchaser_info')}
        </Text>
      </div>
      <PurchaserFields
        isExchange={isExchange}
        purchaser={initialValues}
        showPurchaserForm={showPurchaserForm}
      />
    </>
  );
};

PurchaserForm.propTypes = {
  initialValues: PropTypes.object,
  showPurchaserForm: PropTypes.bool,
  isExchange: PropTypes.bool,
  showInvoiceToggle: PropTypes.bool,
};

export default PurchaserForm;
