import React, { useEffect, useMemo, useRef } from 'react';
import { useDispatch, connect } from 'react-redux';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { Field, change, getFormMeta, touch } from 'redux-form';
import {
  getAvailableDocumentsByNationality,
  getDoc2BrandMap,
  getIgnoredDocuments,
} from '../utils/documentMaps';
import { selectRenderer } from '../utils/formRenderers';

const DocumentTypeField = (props) => {
  const dispatch = useDispatch();
  const {
    name,
    target,
    passengerNationality,
    passengerDocumentType,
    index,
    isDisabled,
    dataValueRef,
    meta,
  } = props;

  const { t } = useTranslation('seats');
  const ignoredDocuments = useMemo(() => getIgnoredDocuments(), []);
  const doc2BrandMap = useMemo(() => getDoc2BrandMap(target), [target]);
  const availableDocumentsByNationality = useMemo(() => getAvailableDocumentsByNationality(), []);
  const items = Object.entries(doc2BrandMap);

  const availableDocuments = useMemo(() => {
    return availableDocumentsByNationality[passengerNationality] || [];
  }, [availableDocumentsByNationality, passengerNationality]);

  const filteredItems = useMemo(() => {
    return availableDocuments.length > 0
      ? items.filter(([, value]) => availableDocuments.includes(value))
      : items;
  }, [items, availableDocuments]);

  // Get the document type from the passenger form if it exists in the available documents
  // otherwise, get the first available document type
  const documentType = useMemo(() => {
    return filteredItems.some(([, value]) => value === passengerDocumentType)
      ? passengerDocumentType
      : filteredItems[0]?.[1] || '';
  }, [filteredItems, passengerDocumentType]);

  const isDocumentTypeTouched = meta?.passengers?.[index]?.documentType?.visited;

  const prevDocumentTypeRef = useRef(documentType);
  // Set the document type in the passenger form
  // and touch the document id field for validation
  useEffect(() => {
    if (!isDocumentTypeTouched || index === undefined) return;

    if (prevDocumentTypeRef.current !== documentType) {
      dispatch(change('passengers', name, documentType));
      dispatch(touch('passengers', dataValueRef));

      prevDocumentTypeRef.current = documentType;
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isDocumentTypeTouched, passengerNationality, documentType]);

  const options = useMemo(() => {
    return filteredItems
      .filter(
        ([key]) =>
          (name !== 'passengers[0].documentType' && name !== 'documentType') ||
          !ignoredDocuments.includes(key),
      )
      .map(([key, value]) => ({
        label: t('identification', { context: key }),
        value,
      }));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [filteredItems, ignoredDocuments, name]);

  return (
    <Field
      label={t('document_type')}
      placeholder={t('document_type')}
      component={selectRenderer}
      options={options}
      // eslint-disable-next-line react/jsx-props-no-spreading
      {...props}
      isDisabled={isDisabled}
    />
  );
};

DocumentTypeField.propTypes = {
  name: PropTypes.string.isRequired,
  dataValueRef: PropTypes.string.isRequired,
  target: PropTypes.string,
  passengerNationality: PropTypes.string,
  passengerDocumentType: PropTypes.string,
  index: PropTypes.number,
  isDisabled: PropTypes.bool,
  meta: PropTypes.any,
};

function mapStateToProps(state) {
  return {
    meta: getFormMeta('passengers')(state),
  };
}

export default connect(mapStateToProps)(DocumentTypeField);
